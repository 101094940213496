.read-more {
	@extend %link-span;
	height: 25px;
	float: left;
	clear: both;
	display: block;

	cursor: pointer;
}

.read-more-container {
	width: 100%;
	float: left;
	clear: both;
	margin-bottom: 5px;
}

.more,
.sponsors {
	float: left;
	clear: both;
	overflow: hidden;
	transition: opacity 0.4s ease-in-out, max-height 0.5s ease-in-out;
	&.visible {
		max-height: 1200px;
		opacity: 1;
	}
}

.more {
	max-height: 3 * $line-height;

	> div {
		//@extend %text-content;
	}
}

.sponsors {
	max-height: 0px;
}

.sponsor {
	margin-top: 30px;
	padding-bottom: 30px;
	strong {
		display: block;
		font-weight: normal;
		padding-bottom: 10px;
	}

	a {
		display: inline-block;
		border-radius: 20px;
		padding: 20px;
		background: #000;
		border: none !important;
		text-decoration: none !important;
		overflow: hidden;

		&[href=''] {
			pointer-events: none;
		}
	}

	img {
		max-height: 100px;
		max-width: 100px;
		width: auto;
	}
}
