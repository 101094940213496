.trail {
	@extend %fullheight;
	padding-bottom: 120px;

	//padding-bottom: calc(40vh + 60px);
	h1 {
		margin-bottom: 15px;
	}
	> article {
		&:first-child() {
		}
	}

	figure {
		@extend %fullwidth;
		position: absolute;
		bottom: 0;
		left: 0;
		height: 40vh;
		transition: opacity 0.2s ease-in;
		opacity: 0;

		img {
			display: none;
		}
	}

	&.sized {
		figure {
			opacity: 1;
		}
	}

	.share,
	> article {
		+ article {
			padding-top: 20px;
		}
	}
}
