.odd {
	h2,
	h1 {
		color: $cRed;
	}

	article {
		a {
			@extend %link-red;
		}
	}

	.safe-link {
		@extend %link-span-red;
	}

	.read-more {
		@extend %link-span-red;
	}

	.share {
		> span {
			clear: both;
			background: url('../../images/share-icon-red.svg') no-repeat center
				center transparent;
			background-size: 20px 22px;
		}

		&.with-text {
			> span,
			> span > span {
				@extend %link-span-red;
				background: none;
				&:before {
					background: url('../../images/share-icon-red.svg') no-repeat
						center center transparent;
					background-size: 20px 22px;
				}
			}
		}
	}

	.map {
		> a {
			&:after {
				background: url('../../images/map-red.svg') no-repeat right
					center transparent;
				background-size: 24px 22px;
			}
		}

		&.on-left {
			//@extend %link-span-red;
			> a {
				//color: $cRed;
				@extend %link-red;

				&:before {
					background: url('../../images/map-red.svg') no-repeat right
						center transparent;
					background-size: 24px 22px;
				}
				&:after {
					background: url('../../images/map.svg') no-repeat right
						center transparent;
					background-size: 24px 22px;
				}
			}
		}
	}
}

.even {
	h2,
	h1 {
		color: $cBlue;
	}

	article {
		a {
			@extend %link-blue;
		}
	}

	.safe-link {
		@extend %link-span-blue;
	}

	.read-more {
		@extend %link-span-blue;
	}

	.maps em {
		background-color: $cBlue;
	}

	.btn {
		&.red {
			@include focus($cBlueBgHover);
			@extend %arrow-blue;
			background-position: right center;

			color: $cBlue;
			&:hover {
				background-color: $cBlueBgHover;
			}
			&:focus {
				box-shadow: inset 15px 0 15px -15px $cBlue;
			}
		}
	}
	//

	.progress-bar {
		background-color: $cBlueBg;
		> div {
			background-color: $cBlue;
		}
	}

	.share {
		> span {
			clear: both;
			background: url('../../images/share-icon-blue.svg') no-repeat center
				center transparent;
			background-size: 20px 22px;
		}

		&.with-text {
			> span,
			> span > span {
				@extend %link-span-blue;
				background: none;
				&:before {
					background: url('../../images/share-icon-blue.svg')
						no-repeat center center transparent;
					background-size: 20px 22px;
				}
			}
		}
	}

	.map {
		> a {
			&:after {
				background: url('../../images/map-blue.svg') no-repeat right
					center transparent;
				background-size: 24px 22px;
			}
		}

		&.on-left {
			//@extend %link-span-blue;
			> a {
				@extend %link-blue;

				&:before {
					background: url('../../images/map-blue.svg') no-repeat right
						center transparent;
					background-size: 24px 22px;
				}
				&:after {
					background: url('../../images/map.svg') no-repeat right
						center transparent;
					background-size: 24px 22px;
				}
			}
		}
	}
}
