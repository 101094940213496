.content-360 {
	// detail
	width: 100%;
	height: 50vh;
}

.content-360-image {
	// in slider
	position: relative;

	&:before {
		@extend %overlay-icon;
		content: '';
		background: url(../../images/360-icon-red.svg) no-repeat center;
	}
}
#gallery-view {
}

#krpanoSWFObject {
	& > div {
		&:nth-child(1) {
			& > div {
				&:nth-child(4) {
					display: none !important;
				}
			}
		}
	}
}

.even {
	.content-360-image {
		&:before {
			background: url(../../images/360-icon.svg) no-repeat center;
		}
	}
}
