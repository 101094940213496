.progress-bar {
	display: block;
	width: 100%;
	height: 4px;
	overflow: hidden;
	background-color: $cRedBg;
	position: relative;
	transition: opacity 0.3s ease-in;
	opacity: 1;

	> div {
		height: 4px;
		width: 0%;
		background-color: $cRed;
		position: absolute;
		left: 0;
		top: 0;
		transition: width 0.2s ease-in;
	}

	&.progress-section {
		margin-top: -5px;
		margin-bottom: 30px;
	}

	&.progress-overall {
		@extend %fullwidth;
		//margin-top: -$page-padding;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		background-color: #dbdbdc;
		> div {
			background-color: #bfc0c2;
		}
	}
}

.scrolling {
	.progress-bar {
		&.progress-section {
			opacity: 0;
		}
	}
}
