.search-headline {
	h2 {
		margin-bottom: 0px;

		a,
		span {
			display: block;
			border-bottom: none;
			padding: 0px 0px 30px;
			color: $cPrimary;
			cursor: pointer;

			background: url('../../images/search.svg') no-repeat left bottom
				transparent;
			background-size: 25px 25px;

			&:hover,
			&:focus {
				color: $cLinkHover;
			}
		}
	}
}

#search-container {
	position: relative;
	min-height: 200px;
	.lds-ring {
		div {
			border-color: $cGrey transparent transparent transparent;
		}
	}
}

#search {
	//@extend %modal;

	h1 {
		display: none;
	}

	label {
	}

	.content {
		padding-bottom: 100px;
	}

	fieldset {
		padding-top: 5px;
		padding-bottom: 30px;
	}

	input {
		display: block;
		padding: 5px 10px 5px 40px;
		font-size: 20px;
		background: url('../../images/search.svg') no-repeat left center
			transparent;
		background-size: 25px 25px;
		border-bottom: 1px solid $cPrimary;
	}
}
