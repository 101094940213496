// VARS
$circle-width: 0.22em;

// colors default
$bad-color: #ffd6d6;
$moderate-color: #ffe3ca;
$moderate-text-color: #d4a379;
$good-color: #ecffe2;

$secondary-color: #ffffff;

.chart-wrap {
	padding-bottom: 40px;
}

// CIRCLE
// classes 2 extend
.rect-auto {
	clip: rect(auto, auto, auto, auto);
}

.pie {
	position: absolute;
	border: $circle-width solid $secondary-color;
	width: 1 - (2 * $circle-width);
	height: 1 - (2 * $circle-width);
	clip: rect(0em, 0.5em, 1em, 0em);
	border-radius: 50%;
	transform: rotate(0deg);
	transition: border-color 0.2s ease-in;
}

.pie-fill {
	transform: rotate(180deg);
}

// main
.ch {
	*,
	*:before,
	*:after {
		box-sizing: content-box;
	}

	position: relative;
	font-size: 200px;
	width: 1em;
	height: 1em;
	border-radius: 50%;
	margin: auto;
	background-color: $secondary-color;

	&.bad {
		.bar,
		.fill {
			border-color: $bad-color;
		}

		> span {
			color: $cRed;
		}
	}
	&.moderate {
		.bar,
		.fill {
			border-color: $moderate-color;
		}

		> span {
			color: $moderate-text-color;
		}
	}
	&.good {
		.bar,
		.fill {
			border-color: $good-color;
		}

		> span {
			color: $cCorrect;
		}
	}

	// centered value inside circle
	> span {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 1;
		font-size: 0.18em;
		font-weight: 400;
		font-size: 40px;
		display: block;
		text-align: center;
		white-space: nowrap;
		transition: all 0.2s ease-out;
		color: $cRed;
	}

	// background inside the circle
	&:after {
		position: absolute;
		top: $circle-width;
		left: $circle-width;
		display: block;
		content: ' ';
		border-radius: 50%;
		background-color: $cBg;
		width: 1 - (2 * $circle-width);
		height: 1 - (2 * $circle-width);

		transition: all 0.2s ease-in;
	}

	// the slice (mask)
	.slice {
		position: absolute;
		width: 1em;
		height: 1em;
		clip: rect(0em, 1em, 1em, 0.5em);
	}

	// circle to show the status
	.bar {
		@extend .pie;
	}

	// loop to create all needed elements automatically
	@for $j from 51 through 100 {
		&.p#{$j} .slice {
			@extend .rect-auto;
		}

		&.p#{$j} .bar:after {
			@extend .pie-fill;
		}

		&.p#{$j} .fill {
			@extend .pie;
			@extend .pie-fill;
		}
	}

	// loop to rotate all 100 circles
	@for $j from 1 through 100 {
		&.p#{$j} .bar {
			transform: rotate((360/100 * $j) + deg);
		}
	}
}
