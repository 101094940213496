.slick-list {
}

.slick-dots {
}

.gallery-container {
	@extend %cf;
	@extend %fullwidth;

	.bgi {
		@extend %landscape;
	}

	.video-js.raf-video-skin .vjs-control-bar {
		bottom: 35px;
	}

	&.no-slides {
		.video-js.raf-video-skin .vjs-control-bar {
			bottom: 15px;
		}

		.zoom {
			top: 0;
			margin-bottom: 0;
			margin-top: 10px;
		}
	}

	&.does-not-have-zoom-icon {
		& + .audio-controls {
			margin-top: 50px;

			& + .audio-info {
				margin-top: 50px;
			}
		}
	}
}

.video-container {
	@extend %landscape;
	position: relative;

	video {
		object-fit: cover;
	}

	.video-js {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

.slick-slider,
.slick-list {
	width: auto !important;
}

#gallery-view {
	padding-bottom: 100px;

	article {
		padding-top: 20px;
	}
}

.gallery-page {
	//@extend %modal-like;
	//@extend %fullwidth;
}

.navigation {
	@extend %cf;
	padding: 0px $page-padding;
	height: 40px;
	margin: 10px 0px 0px;
	text-align: center;
}

.zoom {
	@extend %ico;
	float: right;
	position: relative;
	top: -40px;
	margin: 0px $page-padding -40px 0px;

	&:before {
		background: url('../../images/zoom-in.svg') no-repeat right center
			transparent;
		background-size: 23px 23px;
	}

	&:after {
		background: url('../../images/zoom-in-red.svg') no-repeat right center
			transparent;
		background-size: 23px 23px;
	}

	&:hover {
	}
}

.even {
	.zoom {
		&:after {
			background: url('../../images/zoom-in-blue.svg') no-repeat right
				center transparent;
			background-size: 23px 23px;
		}
	}
}

.dots {
	display: block;
	height: 100%;
	position: relative;
	top: -50px;
	li {
		width: 40px;
		height: 40px;
		display: inline-block;
		transition: opacity 0.2s ease-in;
		position: relative;
		opacity: 0.3;

		span {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			color: transparent;
		}

		&:before {
			content: '';
			display: block;
			position: absolute;
			top: 50%;
			left: 50%;
			width: 24px;
			height: 24px;
			transform: translate(-50%, -50%);

			-webkit-filter: blur(20px);
			filter: blur(20px);
			background-color: rgba(0, 0, 0, 0.8);
			z-index: -1;
		}

		&:after {
			content: '';
			display: inline-block;
			width: 12px;
			height: 12px;
			border-radius: 50%;
			background: $cw;
			position: absolute;
			top: 50%;
			left: 50%;
			margin: -6px 0px 0px -6px;
		}

		&.slick-active {
			opacity: 1;
		}
	}
}
