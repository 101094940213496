.links {
	@extend %cf;
	@extend %fullwidth;
	display: block;

	&.footer {
		border-top: 1px solid $cLine;
		padding-top: 20px;
		padding-bottom: $page-padding;
		margin-top: 60px;
	}

	> li {
		display: block;
		clear: both;
		margin-bottom: 5px;
		padding: 0px $page-padding;

		> a {
			display: inline-block;
			text-decoration: none;
		}

		&.madeby {
			margin-top: 40px;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}
}
