@mixin animate($speed) {
	opacity: 0;
	transform: translateY(50px);
	transition: opacity 0.4s ease-in;
	animation: come-up $speed ease-out forwards;
	animation-delay: 0.5s;
}

.fancy {
	opacity: 0;

	&.animate {
		transition: opacity 1s ease-in;

		&.tt {
			@include animate(1s);
		}
	}
}
.shown {
	transform: translateX(0);
	transform: translateY(0);
	animation: none;
}

@keyframes fade-in {
	to {
		opacity: 1;
	}
}

@keyframes come-up {
	to {
		transform: translateY(0);
		opacity: 1;
	}
}

article,
.question {
	opacity: 1;
	transition: opacity 0.3s ease-in;
}

.slide-left,
.slide-left + .slide-right {
	&.page-slide-enter {
		.page {
			//transform: translateX(100%);
			left: 100%;
			article,
			.question {
				opacity: 0;
			}

			.bottom {
				left: 150%;
			}
		}

		&.page-slide-enter-active {
			.page {
				left: 0%;
				.bottom {
					left: 50%;
				}
			}
		}
	}

	&.page-slide-exit-active {
		.page {
			left: -100%;

			.bottom {
				left: -50%;
			}

			article,
			.question {
				opacity: 0;
			}
		}
	}
}

.slide-right,
.slide-right + .slide-left {
	&.page-slide-enter {
		.page {
			left: -100%;

			.bottom {
				left: -50%;
			}

			article,
			.question {
				opacity: 0;
			}
		}
	}

	&.page-slide-exit-active {
		.page {
			left: 100%;

			.bottom {
				left: 150%;
			}

			article,
			.question {
				opacity: 0;
			}
		}
	}
}

/* FADE TRANSITION */

.fade,
.fade + .fade {
	&.page-slide-enter {
		.page {
			opacity: 0;
			z-index: 99;
		}

		&.page-slide-enter {
			.page {
				opacity: 0;
				z-index: 99;
			}
		}

		&.page-slide-enter-active {
			.page {
				opacity: 1;
				z-index: 99;
			}
		}
	}

	&.page-slide-exit {
		.page {
			opacity: 1;
			z-index: 9;
		}
	}

	&.page-slide-exit-active {
		.page {
			opacity: 0;
			z-index: 9;
		}
	}
}
