%modal-like {
	display: block;
	height: calc(100vh - 88px);
}

%modal {
	position: fixed;
	top: 0px;
	left: 0;
	//width: 0px;
	//height: 0px;
	overflow: hidden;
	background-color: $cw; // rgba(238, 238, 238, 0.62);
	opacity: 0;
	pointer-events: none;
	z-index: 999;
	width: 100%;
	height: 100%;

	transition: opacity 0.2s ease-in-out;

	.modal-head {
		z-index: 1;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		padding: 0px $page-padding;
		background: $cBg;

		.headline {
			max-width: calc(100% - 40px);
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			height: 70px;
			line-height: 70px;
		}
	}

	.content {
		bottom: -100vh;
		position: absolute;
		left: 50%;
		max-width: 768px;
		width: 100%;
		transform: translate(-50%, 0%);
		transition: opacity 0.1s ease-in-out, bottom 0.2s ease-in-out;
		height: calc(100vh - 70px);
		overflow: auto;
		padding: 0px $page-padding $page-padding;
	}

	&.visible {
		pointer-events: auto;
		opacity: 1;
		z-index: 999;

		.content {
			bottom: 0px;
		}
	}
}
