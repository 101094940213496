.directions {
	@extend %has-buttons;

	> article {
		&:first-child() {
			figure {
			}
		}
	}
}

.more-images {
	display: block;
	li {
		display: block;
		padding-bottom: 20px;

		&:last-child {
			padding-bottom: 0;
		}
	}
}
