.homepage {
	//@extend #head;

	> article {
		position: relative;
		z-index: 9;

		+ article {
			padding-top: 30px;
		}
	}
}
