.content-zoom {
	// detail

	width: 100%;
	height: 50vh;
}

.content-zoom-image {
	// in slider
	position: relative;

	&:before {
		@extend %overlay-icon;
		content: '';
		background: url(../../images/zoomimage-icon-red.svg) no-repeat center;
	}
}

#gallery-view {
	.content-zoom {
	}
}

[title='Zoom in'],
[title='Go home'],
[title='Zoom out'],
[title='Toggle full page'] {
	opacity: 0.4;
	background-color: #050a28 !important;
	width: 35px;
	height: 35px;
	padding: 10px !important;
	margin-right: 2px !important;
	cursor: pointer;
	transition: opacity 0.2s ease-in-out;
	&:hover {
		opacity: 1;
	}
	img {
		width: 15px;
		height: auto;
		position: absolute !important;
		left: 10px !important;
		top: 10px !important;
	}
}
