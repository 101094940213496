%arrow-red {
	background: url('../../images/arrow-long-red.svg') no-repeat right 10px
		$cRedBg;
	background-size: 55px 16px;
	background-origin: content-box;
}

%arrow-blue {
	background: url('../../images/arrow-long-blue.svg') no-repeat right 10px
		$cBlueBg;
	background-size: 55px 16px;
	background-origin: content-box;
}

%arrow-black {
	background-image: url('../../images/arrow-long.svg') !important;
	background-repeat: no-repeat;
	background-position: right 5px;
	background-color: transparent !important;
	background-size: 55px 16px;
	background-origin: content-box;
}

%close {
	display: block;
	position: absolute;
	width: 25px;
	height: 25px;
	right: 22px;
	top: 21px;
	background: url('../../images/menu-close.svg') no-repeat center center
		transparent;
	background-size: 16px 16px;
	cursor: pointer;
	border: none !important;
	transition: all 0.2s ease-in;
}

%back {
	display: block;
	position: absolute;
	min-width: 25px;
	height: 25px;
	left: 17px;
	top: 21px;
	background: url('../../images/back-arrow.svg') no-repeat 7px center
		transparent;
	background-size: 10px 16px;
	cursor: pointer;
	border: none;
	transition: all 0.2s ease-in;

	&.has-text {
		line-height: 26px;
		padding: 0px 10px 0px 30px;
		max-width: calc(100% - 80px);
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}
	&:focus {
		box-shadow: inset 0px 0 0px 30px rgba(20, 20, 20, 0.1);
	}

	&:focus,
	&:hover {
		color: $cPrimary;
	}
}

.centerv {
	@extend %cf;
	position: fixed;
	bottom: 10%;
	padding: $page-padding;
	left: 50%;
	width: auto;
	z-index: 11;
	max-width: 768px;
	transform: translate(-50%, 0%);
	transition: left 300ms ease-out;

	&.two {
		.btn {
			width: 50%;
			width: calc(50% - 3px);
			float: left;

			+ .btn {
				float: right;
			}
		}
	}

	&.stack {
		.btn {
			width: auto;
			margin-top: 6px;
			padding: 12px 40px;
			text-align: center;
			&:hover {
				background-color: #eee;
			}
		}
	}

	&.fullwidth {
		padding: 0px;
		.btn {
			padding: $page-padding 85px $page-padding $page-padding;
			font-size: 22px;
			background-position: right $page-padding top 30px;
			background-origin: padding-box;
		}
	}
}

.bottom {
	@extend %cf;
	position: fixed;
	bottom: 0px;
	padding: $page-padding;
	left: 50%;
	width: 100%;
	z-index: 11;
	max-width: 768px;
	transform: translate(-50%, 0%);
	transition: left 300ms ease-out;

	&.two {
		.btn {
			width: 50%;
			width: calc(50% - 3px);
			float: left;

			+ .btn {
				float: right;
			}
		}
	}

	&.stack {
		.btn {
			width: auto;
			margin-top: 6px;
			padding: 12px 40px;
		}
	}

	&.fullwidth {
		padding: 0px;
		.btn {
			padding: $page-padding 85px $page-padding $page-padding;
			font-size: 22px;
			background-position: right $page-padding top 30px;
			background-origin: padding-box;
		}
	}
}

@keyframes effect_dylan {
	50% {
		transform: scale(1.5, 1.5);
		opacity: 0;
	}
	99% {
		transform: scale(0.001, 0.001);
		opacity: 0;
	}
	100% {
		transform: scale(0.001, 0.001);
		opacity: 1;
	}
}

@mixin focus($color) {
	position: relative;
	overflow: hidden;

	&:after {
		content: '';
		background-color: $color;
		display: block;
		position: absolute;
		border-radius: 50%;
		z-index: 0;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		transform: scale(0.001, 0.001);
	}

	&.clicked {
		outline: 0;
		&:after {
			animation: effect_dylan 0.3s ease-out;
		}
	}
}

.btn {
	display: block;
	padding: 12px 20px;
	text-transform: uppercase;
	text-align: left;
	font-size: 30px;
	text-decoration: none;
	border-bottom: none;
	cursor: pointer;

	transition: all 300ms ease-out;

	&.red {
		@include focus($cRedBgHover);
		@extend %arrow-red;
		background-position: right center;
		color: $cRed;

		&:hover {
			background-color: $cRedBgHover;
		}
		&:focus {
			box-shadow: inset 15px 0 15px -15px $cRed;
		}
	}

	&.na {
		background-image: none !important;
	}

	&.disabled {
		opacity: 0;
		pointer-events: none;
	}

	&:hover {
		text-decoration: none;
	}

	&:focus {
		box-shadow: inset 0px 0 0px 2px $cRed;
	}
}

.close {
	@extend %close;
	&:focus {
		box-shadow: inset 0px 0 0px 30px rgba(20, 20, 20, 0.1);
	}
}

.back {
	@extend %back;
}
