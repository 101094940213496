.location {
	> article {
		&:first-child {
			figure {
				margin-bottom: 30px;
			}
		}
	}

	.share,
	> article {
		+ article {
			padding-top: 20px;
		}
	}
}

.locations-list,
.sections-list,
.trails-list {
	@extend %cf;
	@extend %fullwidth;

	a {
		border-bottom: none !important;
	}
	> li {
		&:nth-child(odd) {
			a {
				background-color: $cRedBg;
				strong {
					@extend %arrow-red;
					background-color: transparent;
					color: $cRed;
				}
				span {
					color: $cRed;
				}

				em {
					background-color: $cRedLight;
				}

				&:focus {
					background-color: $cRedBgHover;
				}
			}
		}
		&:nth-child(even) {
			a {
				background-color: $cBlueBg;
				strong {
					@extend %arrow-blue;
					background-color: transparent;
					color: $cBlue;
				}
				span {
					color: $cBlue;
				}
				em {
					background-color: $cBlue;
				}

				&:focus {
					background-color: $cBlueBgHover;
				}
			}
		}

		a {
			transition: all 300ms ease-out;

			&:hover,
			&:focus {
				background-color: $cw;
			}
		}

		&:first-child {
		}

		&.has-location {
			a:first-child {
				div {
					padding-bottom: 5px;
				}

				+ a {
					div {
						padding-top: 5px;
					}
				}
			}
		}
	}
	figure {
		@extend %landscape;
	}
	a {
		display: block;
		position: relative;
		text-decoration: none;
		color: $cPrimary;
		border: none;

		&:hover {
			color: inherit;
		}

		+ a {
			strong {
				@extend %arrow-black;
				background-position: right 6px !important;
				font-size: 20px;
				background-color: transparent;
				color: $cPrimary !important;
				text-transform: none;
			}
		}
	}

	div {
		position: relative;
		padding: 40px 20px 30px;
	}

	em {
		@extend %no;
		position: absolute;
		top: -25px;
		left: 0;
	}

	strong {
		display: block;
		font-size: 30px;
		line-height: 36px;
		font-weight: normal;
		text-transform: uppercase;
		padding: 4px 0px 4px;
		letter-spacing: 0.5px;

		span {
			font-size: inherit;
		}
	}

	span {
		display: block;
		padding-right: 70px;
		font-size: 20px;
	}

	+ .links {
		margin-top: 30px;
		margin-bottom: 30px;
	}
}

.locations-list {
	strong + span {
		text-transform: lowercase;
	}

	&.loc-ico {
		> li {
			a {
				em {
					background-image: url('../../images/location.svg');
					background-repeat: no-repeat;
					background-position: center center;
					background-size: 26px 30px;
				}
			}
		}
	}

	&.concept-ico {
		> li {
			a {
				em {
					background-image: url('../../images/concept.svg');
					background-repeat: no-repeat;
					background-position: center center;
					background-size: 23px 31px;
				}
			}
		}
	}
}

.arrow-list {
	@extend %cf;
	display: block;

	> li {
		display: block;
		margin-bottom: 10px;
		a {
			transition: background-color 0.2s ease-in;
			@extend %arrow-red;
			background-color: $cRedBg;
			display: block;
			text-decoration: none;
			border-bottom: none;
			font-size: 30px;
			line-height: 1.2;
			letter-spacing: 0.5px;
			color: $cRed;
			text-transform: uppercase;
			padding: 15px 20px;

			strong {
				display: block;
				font-weight: normal;
				padding-right: 70px;
			}

			&:focus,
			&:hover {
				background-color: $cRedBgHover;
			}
		}

		&:last-child {
			margin-bottom: 0;
		}
	}
}

@keyframes fadein {
	0% {
		opacity: 0;
	}
	66% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.categories,
.trails-list-wrap {
	overflow: hidden;
	height: 0;
	opacity: 0;

	&.visible {
		overflow: visible;
		display: block;
		height: auto;
		margin-top: 16px;

		animation: 0.5s ease 0s normal forwards 1 fadein;
	}
}

.trails-list-wrap {
}
