#audio-player {
	display: none;
}

.audio-controls {
	float: left;
	position: relative;
	top: -40px;
	margin: 0px 0px -40px 0; //$page-padding;
}

.audio-icon {
	@extend %ico;
	margin-left: -10px;
	float: left;

	&.clicked {
		&:before {
			background: url('../../images/audio/audio-icon-red-full.svg')
				no-repeat center center / 15px 23px;
		}
		&:after {
			background: url('../../images/audio/audio-icon-red-full.svg')
				no-repeat center center / 15px 23px;
		}
	}

	&:before {
		background: url('../../images/audio/audio-icon.svg') no-repeat center
			center / 15px 23px;
	}

	&:after {
		background: url('../../images/audio/audio-icon-red.svg') no-repeat
			center center / 15px 23px;
	}
}

.audio-button {
	@extend %ico;
	float: left;

	&:before {
		background: url('../../images/audio/play-simple.svg') no-repeat center
			center;
	}

	&:after {
		background: url('../../images/audio/play-simple-red.svg') no-repeat
			center center;
	}

	&.pause {
		&:before {
			background: url('../../images/audio/pause-simple.svg') no-repeat
				center center;
		}

		&:after {
			background: url('../../images/audio/pause-simple-red.svg') no-repeat
				center center;
		}
	}
}

.audio-info {
	color: $cRed;
	padding: 0px $page-padding;
	transition: opacity 0.2s ease-in;
	opacity: 0;
	pointer-events: none;
	overflow: hidden;
	height: 0;

	p {
		display: block;
		padding-top: 10px;
	}

	&.visible {
		padding: 10px $page-padding;
		opacity: 1;
		pointer-events: all;
		height: auto;
	}
}

.even {
	.audio-button {
		&:after {
			background: url('../../images/audio/play-simple-blue.svg') no-repeat
				center center;
		}

		&.pause {
			&:after {
				background: url('../../images/audio/pause-simple-blue.svg')
					no-repeat center center;
			}
		}
	}

	.audio-icon {
		&.clicked {
			&:before {
				background: url('../../images/audio/audio-icon-blue-full.svg')
					no-repeat center center / 15px 23px;
			}
			&:after {
				background: url('../../images/audio/audio-icon-blue-full.svg')
					no-repeat center center / 15px 23px;
			}
		}

		&:before {
			background: url('../../images/audio/audio-icon.svg') no-repeat
				center center / 15px 23px;
		}

		&:after {
			background: url('../../images/audio/audio-icon-blue.svg') no-repeat
				center center / 15px 23px;
		}
	}

	.audio-info {
		color: $cBlue;
	}
}
