%cf {
	&:before,
	&:after {
		content: '';
		display: table;
		clear: both;
	}
}

%no {
	height: 50px;
	line-height: 50px;
	padding: 0px 30px;
	font-size: 40px;
	font-weight: normal;
	font-style: normal;
	color: $cw;
}

%ico {
	position: relative;
	display: block;
	width: 40px;
	height: 40px;
	cursor: pointer;
	border-bottom: none !important;
	text-decoration: none !important;

	span {
		display: none;
	}

	&:before,
	&:after {
		content: '';
		display: block;
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: contain;
		transition: opacity 0.2s linear;
		background-color: transparent;
		z-index: 1;
	}

	&:before {
		opacity: 1;
		transition: opacity 0.1s linear;
	}

	&:hover {
		&:before {
			opacity: 0;
		}
		&:after {
			opacity: 1;
		}
	}
}

%link-span {
	color: $cLink;
	border-bottom: none;
	cursor: pointer;

	> span {
		color: $cLink;
		border-bottom: 1px solid $cLink;
	}

	&:hover,
	&:focus {
		border-bottom: 1px solid transparent;
		text-decoration: none;
		color: $cLinkHover;
		> span {
			color: $cLinkHover;
			border-bottom-color: $cLinkHover;
		}
	}
}

%link-span-red {
	@extend %link-span;
	color: $cLinkRed;
	> span {
		color: $cLinkRed;
		border-bottom: 1px solid $cLinkRed;
	}
	&:hover,
	&:focus {
		color: $cLinkRedHover;
		> span {
			color: $cLinkRedHover;
			border-bottom-color: $cLinkRedHover;
		}
	}
}

%link-span-blue {
	@extend %link-span;
	color: $cLinkBlue;
	> span {
		color: $cLinkBlue;
		border-bottom: 1px solid $cLinkBlue;
	}
	&:hover,
	&:focus {
		color: $cLinkBlueHover;
		> span {
			color: $cLinkBlueHover;
			border-bottom-color: $cLinkBlueHover;
		}
	}
}

%link {
	color: $cLink;
	border-bottom: 1px solid $cLink;

	&:hover,
	&:focus {
		text-decoration: none;
		color: $cLinkHover;
		border-bottom-color: $cLinkHover;
	}
}

%link-red {
	@extend %link;
	color: $cLinkRed;
	border-bottom: 1px solid $cLinkRed;

	&:hover,
	&:focus {
		color: $cLinkRedHover;
		border-bottom-color: $cLinkRedHover;
	}
}

%link-blue {
	@extend %link;
	color: $cLinkBlue;
	border-bottom: 1px solid $cLinkBlue;

	&:hover,
	&:focus {
		color: $cLinkBlueHover;
		border-bottom-color: $cLinkBlueHover;
	}
}

%fullwidth {
	margin-left: -$page-padding;
	width: calc(100% + #{2 * $page-padding});
}

%fullheight {
	min-height: calc(100vh - #{$content-top-padding + $page-padding});
}

%rounded {
	border-radius: 50%;
	overflow: hidden;
}

%square {
	&:after {
		content: '';
		display: block;
		padding-bottom: 100%;
	}
}

%rectangle {
	&:after {
		content: '';
		display: block;
		padding-bottom: 50%;
	}
}

%landscape {
	&:after {
		content: '';
		display: block;
		padding-bottom: 56.25%;
	}
}

.invisible {
	opacity: 0;
	visibility: hidden;
}

.bgi {
	background-size: cover;
	background-position: center top;
	background-repeat: no-repeat;
	position: relative;
	min-height: 100px;
	display: block;
	border: none !important;

	> img {
		display: none !important;
	}
}
