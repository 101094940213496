%overlay-icon {
	position: absolute;
	opacity: 0.5;
	transition: opacity 0.4s ease-in;
	width: 80px;
	height: 80px;
	left: 50%;
	top: 50%;
	margin-left: -40px;
	margin-top: -40px;
	border-width: 0;
	background-color: transparent !important;
	font-size: 80px;
	line-height: 1;

	&:hover {
		opacity: 1;
	}
}

.legal {
	font-size: 14px;
	padding-top: 10px;
}
