.share {
	display: block;
	clear: both;
	height: 50px;
	overflow: hidden;

	li,
	a,
	span {
		display: block;
		float: left;
		width: 45px;
		height: 45px;
		cursor: pointer;
	}

	a {
		border-bottom: none;
	}

	> span {
		clear: both;

		transition: all 0.3s ease-in-out;
		margin-left: -7px;
		position: relative;
		border-bottom: none !important;

		&:after,
		&:after {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: url('../../images/share-icon.svg') no-repeat center
				center transparent;
			background-size: 20px 22px;
			transition: opacity 0.2s ease-in;
		}

		&:after {
			opacity: 0;
		}

		&:focus,
		&:hover {
			//box-shadow: inset 0px 0 2px 25px rgba(20, 20, 20, 0.1);
			&:after {
				opacity: 1;
			}
		}
	}

	ul {
		@extend %cf;
		display: block;
		clear: both;
		height: 45px;
		margin: 0px 0px 0px 0px;
		padding: 0;
		opacity: 0;
		transition: opacity 0.3s ease-in-out;

		li {
			display: block;
			padding: 0;
			margin: 0;
			cursor: pointer;

			&:last-child {
				margin-right: 0;
			}

			&.fb {
				background: url('../../images/facebook.svg') no-repeat center
					center transparent;
				background-size: 35px 35px;
				border-radius: 35px;
			}
			&.tw {
				background: url('../../images/twitter.svg') no-repeat center
					center transparent;
				background-size: 35px 35px;
				border-radius: 35px;
			}
			&.em {
				background: url('../../images/email.png') no-repeat center
					center transparent;
				background-size: 35px 35px;

				a {
					border-radius: 35px;
					border: none !important;
					&:focus {
						box-shadow: inset 0px 0 2px 35px rgba(20, 20, 20, 0.1);
					}
				}
			}

			&:focus {
				box-shadow: inset 0px 0 2px 35px rgba(20, 20, 20, 0.1);
			}
		}
	}

	&.clicked {
		> span {
			opacity: 0;
			margin-top: -40px;
		}
		ul {
			opacity: 1;
		}
	}

	&.with-text {
		height: 30px;
		margin-bottom: 10px;

		> span {
			height: 30px;

			&:after,
			&:before {
				display: none;
			}

			&:focus,
			&:hover {
				//box-shadow: inset 0px 0 2px 25px rgba(20, 20, 20, 0.1);
				> span {
					&:after {
						opacity: 1;
					}
				}
			}
		}

		> span,
		> span > span {
			@extend %link-span;
			width: auto;
			padding-right: 0px;
			background: none;
			margin-left: 0px;
			height: auto;
			position: relative;
		}

		> span > span {
			&:after,
			&:before {
				content: '';
				display: block;
				width: 25px;
				height: 29px;
				position: absolute;
				right: -45px;
				top: 0;
				background: url('../../images/share-icon.svg') no-repeat center
					center transparent;
				background-size: 20px 22px;
				transition: opacity 0.2s ease-in;
				opacity: 1;
			}

			&:after {
				opacity: 0;
			}
		}

		> ul {
			position: relative;
			top: 5px;
		}

		&.clicked {
			height: 85px;

			> span {
				opacity: 1;
				margin-top: 0px;
			}
			ul {
				opacity: 1;
			}
		}
	}
}
