.logo {
	display: block;
	width: 81px;
	height: 51px;
	float: left;
	background: url('../../images/rafm-logo.svg') no-repeat left center
		transparent;
	background-size: contain;
	border: none;
	transition: opacity 0.2s ease-in-out;
	cursor: pointer;

	span {
		display: none;
	}

	@include respond-to(mobile-large) {
	}

	@include respond-to(tablet-portrait) {
	}
}

#head {
	position: fixed;
	padding: 18px $page-padding;
	top: 0;
	background-color: $cBg;
	z-index: 99;
	border-bottom: 1px solid transparent;
	left: 50%;
	width: 100%;
	max-width: 768px;
	transform: translate(-50%, 0%);
	transition: border-color 300ms ease-in, padding 200ms ease-in;
	min-height: 88px;

	.close {
		top: 30px;
	}

	.back {
		top: 30px;
	}

	.headline {
		transition: margin 200ms ease-in;
		margin-bottom: 0;
		display: block;
		float: left;
		max-width: calc(100% - 40px);
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		margin-top: 12px;
	}

	&.modal-opened {
		z-index: 8;
	}

	&.scrolling {
		padding: 10px $page-padding;
		min-height: 50px;

		.headline {
			margin-top: 1px;
		}

		.logo {
			display: none;
		}

		&.show-back {
			border-bottom-color: #dbdbdc;
			.logo {
				@extend %back;
				display: inline-block;
				transition: none;
				top: 12px;
			}
		}

		.close,
		.back {
			top: 12px;
		}
	}
}

.scrolling {
	#head {
		border-bottom-color: #dbdbdc;
		padding: 10px $page-padding;
		min-height: 50px;

		.headline {
			margin-top: 1px;
		}

		.logo {
			display: none;
		}

		.close,
		.back {
			top: 12px;
		}
	}

	&.show-back {
		#head {
			.logo {
				@extend %back;
				display: inline-block;
				transition: none;
				top: 12px;
			}
		}
	}
}

.modal-page {
	#head {
		background-color: $cw;
	}
}

.subtitle {
	margin-bottom: 25px;
}
