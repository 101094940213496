.questions {
	@extend %has-buttons;
}

.options-wrap {
	@extend %cf;
	@extend %fullwidth;
	padding-top: 20px;

	ol {
		display: block;
		counter-reset: item;
		list-style-type: none;

		li {
			@extend %cf;
			@include focus($selectedOption);

			display: list-item;
			padding: 15px $page-padding 15px 90px;
			transition: background-color 0.3s ease-in-out;
			background-color: $cw;
			margin-bottom: 1px;
			cursor: pointer;

			&:before {
				content: counter(item, upper-alpha);
				counter-increment: item;
				display: block;
				float: left;
				width: 60px;
				margin: 1px 0px 0px -60px;
				font-size: 40px;
				line-height: 1;
			}

			&:focus {
				box-shadow: inset 15px 0 15px -15px $focusColor;
			}
			img {
				width: 100%;
				float: left;
			}

			&.selected {
				background-color: $selectedOption;
			}
		}
	}
}

.options-text {
	display: block;
	li {
		display: block;
	}
}

.mix {
	img {
		width: 100%;
	}

	figure {
		clear: both;
		margin-bottom: 25px;
	}
}
