.map {
	@extend %cf;
	margin-bottom: 15px;

	&.on-left {
		> a,
		span {
			float: left;
		}
	}

	> a,
	span {
		display: block;
		float: right;
		color: $cPrimary;
		text-decoration: none;
		border-bottom: none;
		padding: 0px 33px 0px 0px;
		position: relative;
		cursor: pointer;

		&:after,
		&:before {
			content: '';
			display: block;
			position: absolute;
			width: 24px;
			height: 22px;
			right: 0;
			top: 4px;
			background: url('../../images/map.svg') no-repeat right center
				transparent;
			background-size: 24px 22px;
			transition: opacity 0.2s ease-in;
		}
		&:after {
			opacity: 0;
		}

		&:hover,
		&:focus {
			&:after {
				opacity: 1;
			}
		}
	}
}

.map-page {
	@extend %modal-like;
	@extend %fullwidth;
}

#map-view {
	height: 100%;

	.content {
		padding: 0;
	}
}

/*** IMAGES *********/

$zoomWidth: 38px;
$zoomHeight: 30px;
$pointWidth: 39px;
$pointHeight: 29px;

.maps-list {
	display: block;
	clear: both;
	position: relative;
}

.map-list-item {
	display: block;
	list-style-type: none;
	z-index: 1;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	opacity: 0;
	pointer-events: none;
	transition: opacity 0.2s ease-in;

	img {
		width: 100%;
		height: auto;
		z-index: 1;
	}

	&.active {
		opacity: 1;
		z-index: 2;
		pointer-events: all;
	}
}

.location-name {
	position: absolute;
	width: 100%;
	bottom: 0;
	left: 0;
	background: rgba(2, 3, 23, 0.4);
	font-size: 15px;
	line-height: 30px;
	z-index: 2;
	color: $cw;
	padding: 0px 20px;
}

.back-link {
	position: absolute;
	float: left;
	top: 0;
	left: 0;
	z-index: 2;
	button {
		position: relative;
		float: left;
		width: 45px;
		height: 36px;
		background: rgba(2, 3, 23, 0.4) url('../../images/back-arrow-white.svg')
			no-repeat center center;
	}
}

.map-locations {
	position: absolute;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	z-index: 5;

	.location-pointer {
		position: absolute;
		z-index: 9;
		padding: 0;
		cursor: pointer;
		&.zoomPoint {
			width: $zoomWidth;
			height: $zoomHeight;
			margin-left: -$zoomWidth/2;
			margin-top: -$zoomHeight/2;
			background: url('../../images/map-zoom.svg') no-repeat center center /
				26px 20px;
			&.active {
				background-size: 38px 30px;
			}
		}
		&.point {
			width: $pointWidth;
			height: $pointHeight;
			margin-left: (-$pointWidth/2 + 3);
			margin-top: (-$pointHeight + 3);
			background: url('../../images/map-location.svg') no-repeat center
				bottom / 27px 20px;
			&.active {
				background-size: 39px 29px;
				background-image: url('../../images/map-location-active.svg');
			}
		}
	}
}

/*** MAPBOX *********/

.mapboxgl-map {
	width: 100%;
	height: 100%;
}

.mapboxgl-marker {
	width: 59px;
	height: 48px;
	background: url('../../images/map-location.svg') no-repeat center bottom;
	background-size: 59px 48px;
	cursor: pointer;

	&.clicked {
		background: url('../../images/map-location-active.svg') no-repeat center
			bottom;
		background-size: 59px 48px;
	}
}

.map-goto {
	display: block;
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	a {
		background-color: $cRedBg;
		strong {
			@extend %arrow-red;
			background-color: transparent;
			color: $cRed;
		}

		em {
			background-color: $cRedLight;
		}

		&:focus {
			background-color: $cRedBgHover;
		}
	}
}
